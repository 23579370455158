import { Flex, type FlexProps, Link } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { type ReactNode, useMemo } from 'react';
import { useDesignTokens } from '../../providers/design_tokens_provider';

interface NavItemProps extends FlexProps {
  icon: (_color: string) => ReactNode;
  path: string;
  children: ReactNode;
  subitems?: {
    name: string;
    path: string;
  }[];
}
export const NavItem = ({ icon, path, children, subitems }: NavItemProps) => {
  const tokens = useDesignTokens();
  const pathname = usePathname();

  const isNavItemSelected = useMemo(() => {
    return pathname.split('/')[1] === path.split('/')[1];
  }, [pathname, path]);

  const isSubRouteSelected = (route: string) => {
    return pathname === route;
  };

  return (
    <>
      <Link href={path} style={{ textDecoration: 'none' }}>
        <Flex
          alignItems='center'
          padding={4}
          marginX={4}
          borderRadius='lg'
          role='group'
          cursor='pointer'
          color={
            isNavItemSelected
              ? tokens.text.primary.color
              : tokens.text.gray.color
          }
          backgroundColor={
            isNavItemSelected ? tokens.background.highlight.color : undefined
          }
        >
          {icon(
            isNavItemSelected
              ? tokens.text.primary.color
              : tokens.text.gray.color,
          )}
          {children}
        </Flex>
      </Link>
      {subitems?.length ? (
        <Flex flexDirection={'column'} alignItems={'center'}>
          {subitems.map((item) => (
            <Link
              href={item.path}
              style={{ textDecoration: 'none' }}
              key={item.path}
            >
              <Flex
                alignItems='center'
                padding={4}
                marginX={4}
                borderRadius='lg'
                role='group'
                cursor='pointer'
                color={
                  isSubRouteSelected(item.path)
                    ? tokens.text.primaryLight.color
                    : tokens.text.gray.color
                }
              >
                {item.name}
              </Flex>
            </Link>
          ))}
        </Flex>
      ) : null}
    </>
  );
};
